import { createSelector } from '@ngrx/store';
import { AppState } from '@state/app.state';

export const selectAuthState = (state: AppState) => state.auth;

export const selectUserInfo = createSelector(selectAuthState, state => state.userInfo);
export const selectIsLogging = createSelector(selectAuthState, state => state.isLogging);
export const selectErrorMsg = createSelector(selectAuthState, state => state.error);
export const selectOTPRequired = createSelector(selectAuthState, state => state.otpRequired);
export const selectFacebookAccounts = createSelector(selectAuthState, state => state.facebookAccounts);
export const selectFacebookAccountSelected = createSelector(selectAuthState, state => state.facebookAccountSelected);
export const selectBusinessIdSelected = createSelector(selectAuthState, state => state.businessIdSelected);
